import Axios from 'axios';

const baseURL =
  process.env.VUE_APP_IN_NETLIFY === 'true'
    ? '/api/'
    : process.env.NODE_ENV === 'development'
    ? 'http://dev.localhost.com/wordpress/'
    : 'https://ucl-sleepstar.diascreative.net/';

function createInstance() {
  const instance = Axios.create({
    baseURL
  });

  const token = window.localStorage.getItem('token');

  if (token) {
    // instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  return instance;
}

const axios = createInstance();

export default axios;
