import axios from '@/axios';

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  meta: {},
  children: localStorage.children ? JSON.parse(localStorage.children) : []
};

const mutations = {
  setMeta(state, meta = {}) {
    state.meta = meta;
  },
  setChildren(state, children = []): void {
    state.children = children;
    return localStorage.setItem('children', JSON.stringify(children));
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  getDataAPI: async ({ commit }): Promise<any> => {
    return await axios
      .get('wp-json/user/v1/me/')
      .then(res => res.data)
      .then(data => {
        commit('setMeta', data.metadata);
        commit('setChildren', data.children);
      })
      .catch(function() {
        // user is not logged in
      });
  }
};

// getters are functions.
const getters = {
  meta: (state): string => state.meta,
  children: (state): string[] => state.children,
  childById: (state): any => (id): any => {
    const child = state.children.filter(child => child.id === id);
    if (child.length === 1) {
      return child[0];
    }

    return {};
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
