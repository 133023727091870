<template>
  <IonApp>
    <ion-router-outlet id="main" :key="route.fullPath" />

    <ion-tabs v-if="false">
      <slot name="bottom">
        <div
          class="ion-padding ion-text-center offline-message"
          :class="{ 'offline-message--on': isOffline }"
        >
          You are offline. You will not be able to store any data.<br />Some functionality will be
          limited.
        </div>
        <ion-tab-bar>
          <ion-tab-button tab="home" href="/home" @click="router.push('/home')">
            <ion-icon :icon="searchOutline" />
            <ion-label>Search</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="profile" :href="myChildUrl" @click="router.push(myChildUrl)">
            <ion-icon :icon="personOutline" />
            <ion-label>{{ myChildButtonLabel }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="about" href="/about" @click="router.push('/about')">
            <ion-icon :icon="informationOutline" />
            <ion-label>About</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="sponsors" href="/sponsors" @click="router.push('/sponsors')">
            <ion-icon :icon="starOutline" />
            <ion-label>Cerebra</ion-label>
          </ion-tab-button>
        </ion-tab-bar>
      </slot>
    </ion-tabs>
  </IonApp>
</template>

<script lang="ts">
import {
  IonLabel,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
  IonApp,
  IonRouterOutlet
} from '@ionic/vue';

import {
  starOutline,
  cogOutline,
  searchOutline,
  heartDislike,
  heart,
  personOutline,
  informationOutline,
  peopleOutline
} from 'ionicons/icons';

import { computed, defineComponent, reactive, toRefs, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'App',
  components: {
    IonLabel,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonIcon,
    IonApp,
    IonRouterOutlet
  },
  setup(): any {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const selectedIndex = ref(0);
    const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

    const state = reactive({ appPages: [], isOffline: false });
    store.dispatch('content/getSleepResourcesFromAPI');
    store.dispatch('content/getSleepProblemsFromAPI');
    store.dispatch('content/getSleepSolutionsFromAPI');
    store.dispatch('user/getDataAPI');

    const isInPage = function(slug) {
      return route.params.id === slug;
    };

    const isLoggedIn = computed(() => store.getters['auth/currentUser']);

    const children = store.getters['user/children'];

    window.addEventListener('offline', function() {
      state.isOffline = true;
    });

    window.addEventListener('online', function() {
      state.isOffline = false;
    });

    return {
      route,
      router,
      ...toRefs(state),
      evenOrOdd: computed(() => store.getters.evenOrOdd),
      isLoggedIn,
      myChildUrl: computed(() => (isLoggedIn.value ? '/profile' : '/register')),
      isInPage,
      selectedIndex,
      labels,
      searchOutline,
      heart,
      heartDislike,
      informationOutline,
      personOutline,
      peopleOutline,
      starOutline,
      cogOutline,
      isSelected: (url: string) => (url === route.path ? 'selected' : ''),
      myChildButtonLabel: computed(() => (children.length === 1 ? 'My child' : 'My child'))
    };
  }
});
</script>
