import axios from '@/axios';
import router from '@/router';

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  wpSleepResources: [],
  wpSleepProblems: [],
  wpSleepSolutions: []
};

const mutations = {
  setSleepResources(state, pages) {
    state.wpSleepResources = pages;
  },
  setSleepProblems(state, pages) {
    state.wpSleepProblems = pages;
  },
  setSleepSolutions(state, pages) {
    state.wpSleepSolutions = pages;
  }
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  getSleepResourcesFromAPI: async ({ commit }) => {
    const newResources = await axios
      .get(
        '/wp-json/wp/v2/resources/?per_page=100&_fields=id,title,slug,content,parent,related&orderby=title&order=asc'
      )
      .then(res => res.data)
      .catch(function() {
        router.replace('/log-out');
      });

    if (newResources) {
      commit('setSleepResources', newResources);
    }
  },
  getSleepProblemsFromAPI: async ({ commit }) => {
    const newSearch = await axios
      .get(
        '/wp-json/wp/v2/problems/?per_page=100&_fields=id,title,slug,content,parent,related&orderby=title&order=asc'
      )
      .then(res => res.data)
      .catch(function(err) {
        router.replace('/log-out');
      });

    if (newSearch) {
      commit('setSleepProblems', newSearch);
    }
  },
  getSleepSolutionsFromAPI: async ({ commit }) => {
    const newSearch = await axios
      .get(
        '/wp-json/wp/v2/solutions/?per_page=100&_fields=id,title,slug,content,parent,related&orderby=title&order=asc'
      )
      .then(res => res.data)
      .catch(function() {
        router.replace('/log-out');
      });

    if (newSearch) {
      commit('setSleepSolutions', newSearch);
    }
  }
};

// getters are functions.
const getters = {
  wpSleepResources: state => state.wpSleepResources,
  wpSleepProblems: state => state.wpSleepProblems,
  wpSleepSolutions: state => state.wpSleepSolutions
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
