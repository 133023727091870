import JwtDecode from 'jwt-decode';

type UserObjectType = {
  role: string;
  name: string;
};

export default class User {
  public role: string;
  public name: string;

  static from(token: string) {
    try {
      const obj: UserObjectType = JwtDecode(token);
      return new User(obj);
    } catch (_) {
      return null;
    }
  }

  constructor(args) {
    this.role = args.role;
    this.name = args.name;
  }

  get isAdmin() {
    return this.role;
  }
}
