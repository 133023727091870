import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    meta: { noAuth: true },
    redirect: '/home'
  },
  {
    alias: '/home',
    name: 'home',
    path: '/',
    meta: { noAuth: true },
    component: () => import('../views/Home.vue')
  },
  {
    name: 'login',
    path: '/login',
    meta: { noAuth: true },
    component: () => import('../views/Login.vue')
  },
  {
    path: '/log-out',
    meta: { noAuth: true },
    component: () => import('../views/LogOut.vue')
  },
  {
    path: '/register',
    meta: { noAuth: true },
    component: () => import('../views/Register.vue')
  },
  {
    path: '/password-request',
    meta: { noAuth: true },
    component: () => import('../views/PasswordRequest.vue')
  },
  {
    name: 'sponsors',
    path: '/sponsors',
    meta: { noAuth: true },
    component: () => import('../views/Sponsors.vue')
  },
  {
    name: 'about',
    path: '/about',
    meta: { noAuth: true },
    component: () => import('../views/About.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue')
  },
  {
    path: '/bye',
    name: 'bye',
    meta: { noAuth: true },
    component: () => import('../views/Bye.vue')
  },
  {
    path: '/profile/child/:id(\\d+)',
    name: 'child',
    component: () => import('../views/Child.vue')
  },
  {
    path: '/profile/child/add',
    name: 'child-create',
    component: () => import('../views/ChildEdit.vue')
  },
  {
    path: '/profile/child/:id(\\d+)/edit',
    name: 'child-edit',
    component: () => import('../views/ChildEdit.vue')
  },
  {
    path: '/profile/child/:id(\\d+)/calendar',
    name: 'child-calendar',
    component: () => import('../views/ChildCalendar.vue')
  },
  {
    path: '/profile/child/:childId(\\d+)/calendar/:entryId(\\d+)',
    name: 'child-calendar-edit',
    component: () => import('../views/ChildCalendarEdit.vue')
  },
  {
    path: '/:type(resources|problems|solutions)',
    name: 'archive',
    meta: { noAuth: true },
    component: () => import('../views/Archive.vue')
  },
  {
    name: 'single',
    path: '/:type(resources|problems|solutions)/:slug',
    meta: { noAuth: true },
    component: () => import('../views/Single.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const authUser = store.getters['auth/currentUser'];

  if (to.meta.noAuth) {
    if (to.name === 'login' && authUser) {
      next('/profile');
    } else {
      next();
    }
  } else {
    if (!authUser) {
      next({ name: 'login' });
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  setTimeout(() => {
    const main = document.querySelector('.ion-page.ion-page-invisible');
    if (!main) {
      return;
    }

    if (main) {
      main.classList.remove('ion-page-invisible');
    }
  }, 400);
});

export default router;
