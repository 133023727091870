import axios from '@/axios';
import User from '@/models/User';

const state = {
  token: localStorage.token,
  user: User.from(localStorage.token)
};

const mutations = {
  login(state, token) {
    localStorage.setItem('token', token);
    state.token = token;
    state.user = User.from(token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  logout(state) {
    state.user = null;
    state.token = null;
    localStorage.removeItem('token');
    localStorage.removeItem('children');
    axios.defaults.headers.common.Authorization = null;
    delete axios.defaults.headers.common.Authorization;
  }
};

const actions = {
  login({ commit }, token) {
    commit('login', token);
  },

  logout({ commit }) {
    commit('logout');
  },

  validate({ state }) {
    return axios({
      url: `/jwt-auth/v1/token/validate`,
      method: 'post',
      headers: {
        Authorization: `Bearer ${state.token}`
      }
    });
  }
};

const getters = {
  token() {
    return state.token;
  },
  currentUser(state) {
    if (!state || !state.user) {
      return null;
    }

    return state.user;
  },
  currentUserName(state) {
    if (!state || !state.user) {
      return null;
    }

    return state.user.name;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
